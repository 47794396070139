/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    PostReferralReportAggregationResultsRequest,
    PostReferralReportAggregationResultsRequestFromJSON,
    PostReferralReportAggregationResultsRequestToJSON,
    PostReferralReportAggregationResultsResponse,
    PostReferralReportAggregationResultsResponseFromJSON,
    PostReferralReportAggregationResultsResponseToJSON,
    UnprocessableEntity,
    UnprocessableEntityFromJSON,
    UnprocessableEntityToJSON,
} from '../models';

export interface PostReferralReportAggregationResultsOperationRequest {
    postReferralReportAggregationResultsRequest: PostReferralReportAggregationResultsRequest;
}

/**
 * ReferralReportsApi - interface
 * 
 * @export
 * @interface ReferralReportsApiInterface
 */
export interface ReferralReportsApiInterface {
    /**
     * 
     * @summary 紹介レポート集計結果生成
     * @param {PostReferralReportAggregationResultsRequest} postReferralReportAggregationResultsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferralReportsApiInterface
     */
    postReferralReportAggregationResultsRaw(requestParameters: PostReferralReportAggregationResultsOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostReferralReportAggregationResultsResponse>>;

    /**
     * 紹介レポート集計結果生成
     */
    postReferralReportAggregationResults(requestParameters: PostReferralReportAggregationResultsOperationRequest, initOverrides?: RequestInit): Promise<PostReferralReportAggregationResultsResponse>;

}

/**
 * 
 */
export class ReferralReportsApi extends runtime.BaseAPI implements ReferralReportsApiInterface {

    /**
     * 紹介レポート集計結果生成
     */
    async postReferralReportAggregationResultsRaw(requestParameters: PostReferralReportAggregationResultsOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostReferralReportAggregationResultsResponse>> {
        if (requestParameters.postReferralReportAggregationResultsRequest === null || requestParameters.postReferralReportAggregationResultsRequest === undefined) {
            throw new runtime.RequiredError('postReferralReportAggregationResultsRequest','Required parameter requestParameters.postReferralReportAggregationResultsRequest was null or undefined when calling postReferralReportAggregationResults.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/referral_reports/aggregation_results`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostReferralReportAggregationResultsRequestToJSON(requestParameters.postReferralReportAggregationResultsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostReferralReportAggregationResultsResponseFromJSON(jsonValue));
    }

    /**
     * 紹介レポート集計結果生成
     */
    async postReferralReportAggregationResults(requestParameters: PostReferralReportAggregationResultsOperationRequest, initOverrides?: RequestInit): Promise<PostReferralReportAggregationResultsResponse> {
        const response = await this.postReferralReportAggregationResultsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
