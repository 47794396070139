/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AreaReportDefinitionDisplayItem,
    AreaReportDefinitionDisplayItemFromJSON,
    AreaReportDefinitionDisplayItemFromJSONTyped,
    AreaReportDefinitionDisplayItemToJSON,
} from './AreaReportDefinitionDisplayItem';
import {
    AreaReportDefinitionFilterCondition,
    AreaReportDefinitionFilterConditionFromJSON,
    AreaReportDefinitionFilterConditionFromJSONTyped,
    AreaReportDefinitionFilterConditionToJSON,
} from './AreaReportDefinitionFilterCondition';
import {
    AreaReportDefinitionSortCondition,
    AreaReportDefinitionSortConditionFromJSON,
    AreaReportDefinitionSortConditionFromJSONTyped,
    AreaReportDefinitionSortConditionToJSON,
} from './AreaReportDefinitionSortCondition';
import {
    AreaReportItem,
    AreaReportItemFromJSON,
    AreaReportItemFromJSONTyped,
    AreaReportItemToJSON,
} from './AreaReportItem';

/**
 * 
 * @export
 * @interface PostAreaReportDefinitionRequest
 */
export interface PostAreaReportDefinitionRequest {
    /**
     * レポート名
     * @type {string}
     * @memberof PostAreaReportDefinitionRequest
     */
    name: string;
    /**
     * 
     * @type {Array<AreaReportItem>}
     * @memberof PostAreaReportDefinitionRequest
     */
    items: Array<AreaReportItem>;
    /**
     * エリアタイプの医療機関ラベルID
     * @type {number}
     * @memberof PostAreaReportDefinitionRequest
     */
    medicalInstitutionLabelId: number;
    /**
     * 
     * @type {Array<AreaReportDefinitionDisplayItem>}
     * @memberof PostAreaReportDefinitionRequest
     */
    displayItems: Array<AreaReportDefinitionDisplayItem>;
    /**
     * 
     * @type {Array<AreaReportDefinitionFilterCondition>}
     * @memberof PostAreaReportDefinitionRequest
     */
    filterConditions: Array<AreaReportDefinitionFilterCondition>;
    /**
     * 
     * @type {Array<AreaReportDefinitionSortCondition>}
     * @memberof PostAreaReportDefinitionRequest
     */
    sortConditions: Array<AreaReportDefinitionSortCondition>;
    /**
     * レポート全体に適用される施設タイプの集計条件
     * @type {Array<number>}
     * @memberof PostAreaReportDefinitionRequest
     */
    aggregationConditionFacilityTypeIds?: Array<number> | null;
}

export function PostAreaReportDefinitionRequestFromJSON(json: any): PostAreaReportDefinitionRequest {
    return PostAreaReportDefinitionRequestFromJSONTyped(json, false);
}

export function PostAreaReportDefinitionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostAreaReportDefinitionRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'items': ((json['items'] as Array<any>).map(AreaReportItemFromJSON)),
        'medicalInstitutionLabelId': json['medical_institution_label_id'],
        'displayItems': ((json['display_items'] as Array<any>).map(AreaReportDefinitionDisplayItemFromJSON)),
        'filterConditions': ((json['filter_conditions'] as Array<any>).map(AreaReportDefinitionFilterConditionFromJSON)),
        'sortConditions': ((json['sort_conditions'] as Array<any>).map(AreaReportDefinitionSortConditionFromJSON)),
        'aggregationConditionFacilityTypeIds': !exists(json, 'aggregation_condition_facility_type_ids') ? undefined : json['aggregation_condition_facility_type_ids'],
    };
}

export function PostAreaReportDefinitionRequestToJSON(value?: PostAreaReportDefinitionRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'items': ((value.items as Array<any>).map(AreaReportItemToJSON)),
        'medical_institution_label_id': value.medicalInstitutionLabelId,
        'display_items': ((value.displayItems as Array<any>).map(AreaReportDefinitionDisplayItemToJSON)),
        'filter_conditions': ((value.filterConditions as Array<any>).map(AreaReportDefinitionFilterConditionToJSON)),
        'sort_conditions': ((value.sortConditions as Array<any>).map(AreaReportDefinitionSortConditionToJSON)),
        'aggregation_condition_facility_type_ids': value.aggregationConditionFacilityTypeIds,
    };
}

