import { useFetchErrorHandle } from '@/src/error/fetchError/hooks/useFetchErrorHandle';
import { useAccount } from '@/src/hooks/useAccount';
import { useBackLinkHref } from '@/src/hooks/useBackLinkHref';
import { useFlag } from '@/src/hooks/useFlag';
import { useResponsive } from '@/src/hooks/useResponsive';
import { useScrim } from '@/src/hooks/useScrim';
import { useTitle } from '@/src/hooks/useTitle';
import { useShowToast } from '@/src/hooks/useToast';
import { msalInstance } from '@/src/middlewares/Auth/config';

import { NavigationPresenter } from './presenter';
import { SpNavigationPresenter } from './spPresenter';
import { PresenterProps } from './types';

import * as Sentry from '@sentry/browser';
import { useRouter } from 'next/router';

type Props = Pick<PresenterProps, 'onClickMenuButton'>;

export const Navigation: React.FC<Props> = (props) => {
  const accountManage = useAccount();
  const scrim = useScrim();
  const router = useRouter();
  const showToast = useShowToast();
  const fetchErrorHandle = useFetchErrorHandle();
  const title = useTitle();
  const backLinkHref = useBackLinkHref();
  const [isShownPopover, showPopover, hidePopover] = useFlag();
  const responsive = useResponsive();

  const logout = async () => {
    scrim.show('ログアウト中です');

    try {
      await msalInstance.logoutRedirect({ postLogoutRedirectUri: '/' });
      accountManage.clearAccount();
      Sentry.setUser(null);
    } catch (error) {
      fetchErrorHandle(error, [400], (error) => {
        switch (error.status) {
          case 400:
            showToast('error', 'ログアウトに失敗しました');
            break;
          default:
            assertIsNever(error.status);
        }
      });
    } finally {
      scrim.hide();
    }
  };

  const presenterProps: PresenterProps = {
    title,
    backLinkHref,
    name: accountManage.account.name,
    onClickMenuButton: props.onClickMenuButton,
    onClickGoToTenantSettings: () => {
      router.push('/tenant_setting');
      hidePopover();
    },
    onClickGoToProfileNotification: () => {
      router.push('/profile/notification');
      hidePopover();
    },
    onClickLogoutButton: logout,
    isShownPopover,
    showPopover,
    hidePopover,
  };

  return responsive.isSp ? (
    <SpNavigationPresenter {...presenterProps} />
  ) : (
    <NavigationPresenter {...presenterProps} />
  );
};
