export const FEATURE_FLAG_ENV_MAP = {
  FEATURE_FLAG_MI_REPORT_STANDARD_REVENUE:
    process.env.FEATURE_FLAG_MI_REPORT_STANDARD_REVENUE,
  FEATURE_FLAG_AREA_REPORT_REST_AREA:
    process.env.FEATURE_FLAG_AREA_REPORT_REST_AREA,
  FEATURE_FLAG_ASSOCIATED_REVERSE_REFERRAL:
    process.env.FEATURE_FLAG_ASSOCIATED_REVERSE_REFERRAL,
  FEATURE_FLAG_DISTANCE_FROM_COMPETITIVES:
    process.env.FEATURE_FLAG_DISTANCE_FROM_COMPETITIVES,
  FEATURE_FLAG_OWN_DEPARTMENT_REPORT_STANDARD_REVENUE:
    process.env.FEATURE_FLAG_OWN_DEPARTMENT_REPORT_STANDARD_REVENUE,
  FEATURE_FLAG_AREA_REPORT_FACILITY_TYPES:
    process.env.FEATURE_FLAG_AREA_REPORT_FACILITY_TYPES,
  FEATURE_FLAG_OWN_DEPARTMENT_REPORT_FACILITY_TYPES:
    process.env.FEATURE_FLAG_OWN_DEPARTMENT_REPORT_FACILITY_TYPES,
  FEATURE_FLAG_MONTH_AGGREGATION_ON_SUMMARY:
    process.env.FEATURE_FLAG_MONTH_AGGREGATION_ON_SUMMARY,
  FEATURE_FLAG_AREA_REPORT_STANDARD_REVENUE:
    process.env.FEATURE_FLAG_AREA_REPORT_STANDARD_REVENUE,
  FEATURE_FLAG_PHASE_MANAGEMENT: process.env.FEATURE_FLAG_PHASE_MANAGEMENT,
} as const;

export const isFeatureFlagEnabled = (key: keyof typeof FEATURE_FLAG_ENV_MAP) =>
  FEATURE_FLAG_ENV_MAP[key] === 'true';
