import {
  AccountsApi,
  ActionListApi,
  Configuration,
  ChartsApi,
  DataAnalysisApi,
  MedicalInstitutionsApi,
  OwnMedicalInstitutionApi,
  SalesMapApi,
  SessionsApi,
  TasksApi,
  TenantsApi,
  EmailsApi,
  UploadedFileApi,
  AlertsApi,
  DoctorsApi,
  CommunicationsApi,
  TagsApi,
  GroupsApi,
  ReferralsApi,
  NotificationsApi,
  ReverseReferralsApi,
  MedicalInstitutionLabelsApi,
  ImportsApi,
  CommunicationLabelsApi,
  OwnDepartmentReportsApi,
  AreaReportsApi,
  CommunicationReportsApi,
  DashboardsApi,
  DiagnosesApi,
  MedicalInstitutionReportsApi,
  SegmentsApi,
  ReferralReportsApi,
  PhaseMastersApi,
} from '@/src/api/generated';
import { generateAcquireAccessToken } from '@/src/middlewares/Auth/config';

export const apiURL = () => {
  if (process.env.APP_ENV === 'test' && process.env.API_HOST) {
    return process.env.API_HOST;
  } else {
    return process.env.API_URL;
  }
};

const configuration = new Configuration({
  basePath: apiURL(),
  headers: {
    X_FORO_CRM_CLIENT_VERSION: process.env.X_FORO_CRM_CLIENT_VERSION,
  },
  accessToken: generateAcquireAccessToken('foroCrmApi'),
  credentials: 'include',
});

export const salesMapApi = new SalesMapApi(configuration);
export const sessionsApi = new SessionsApi(configuration);
export const tasksApi = new TasksApi(configuration);
export const ownMedicalInstitutionApi = new OwnMedicalInstitutionApi(
  configuration,
);
export const medicalInstitutionsApi = new MedicalInstitutionsApi(configuration);
export const actionListApi = new ActionListApi(configuration);
export const chartsApi = new ChartsApi(configuration);
export const tenantsApi = new TenantsApi(configuration);
export const emailsApi = new EmailsApi(configuration);
export const uploadedFileApi = new UploadedFileApi(configuration);
export const alertApi = new AlertsApi(configuration);
export const dataAnalysisApi = new DataAnalysisApi(configuration);
export const accountsApi = new AccountsApi(configuration);
export const doctorsApi = new DoctorsApi(configuration);
export const communicationsApi = new CommunicationsApi(configuration);
export const tagsApi = new TagsApi(configuration);
export const groupsApi = new GroupsApi(configuration);
export const referralsApi = new ReferralsApi(configuration);
export const notificationsApi = new NotificationsApi(configuration);
export const reverseReferralsApi = new ReverseReferralsApi(configuration);
export const medicalInstitutionLabelsApi = new MedicalInstitutionLabelsApi(
  configuration,
);
export const importsApi = new ImportsApi(configuration);
export const communicationLabelsApi = new CommunicationLabelsApi(configuration);
export const medicalInstitutionReportsApi = new MedicalInstitutionReportsApi(
  configuration,
);
export const ownDepartmentReportsApi = new OwnDepartmentReportsApi(
  configuration,
);
export const areaReportsApi = new AreaReportsApi(configuration);
export const communicationReportsApi = new CommunicationReportsApi(
  configuration,
);
export const referralReportsApi = new ReferralReportsApi(configuration);
export const dashboardsApi = new DashboardsApi(configuration);
export const diagnosisApi = new DiagnosesApi(configuration);
export const segmentsApi = new SegmentsApi(configuration);
export const phaseMastersApi = new PhaseMastersApi(configuration);
