import { createContext, RefObject } from 'react';

type ProviderProps = {
  value: RefObject<HTMLDivElement>;
  children: React.ReactNode;
};

export const HookScrollContainerRefContext =
  createContext<RefObject<HTMLDivElement> | null>(null);

export const ScrollContainerRefProvider: React.FC<ProviderProps> = (props) => {
  return (
    <HookScrollContainerRefContext.Provider value={props.value}>
      {props.children}
    </HookScrollContainerRefContext.Provider>
  );
};
