/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ReferralReportFilterConditionMatcher,
    ReferralReportFilterConditionMatcherFromJSON,
    ReferralReportFilterConditionMatcherFromJSONTyped,
    ReferralReportFilterConditionMatcherToJSON,
} from './ReferralReportFilterConditionMatcher';

/**
 * 
 * @export
 * @interface PostReferralReportAggregationResultsRequestFilterConditions
 */
export interface PostReferralReportAggregationResultsRequestFilterConditions {
    /**
     * 
     * @type {string}
     * @memberof PostReferralReportAggregationResultsRequestFilterConditions
     */
    definitionKey: string;
    /**
     * 
     * @type {Array<ReferralReportFilterConditionMatcher>}
     * @memberof PostReferralReportAggregationResultsRequestFilterConditions
     */
    matchingGroups: Array<ReferralReportFilterConditionMatcher>;
}

export function PostReferralReportAggregationResultsRequestFilterConditionsFromJSON(json: any): PostReferralReportAggregationResultsRequestFilterConditions {
    return PostReferralReportAggregationResultsRequestFilterConditionsFromJSONTyped(json, false);
}

export function PostReferralReportAggregationResultsRequestFilterConditionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostReferralReportAggregationResultsRequestFilterConditions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'definitionKey': json['definition_key'],
        'matchingGroups': ((json['matching_groups'] as Array<any>).map(ReferralReportFilterConditionMatcherFromJSON)),
    };
}

export function PostReferralReportAggregationResultsRequestFilterConditionsToJSON(value?: PostReferralReportAggregationResultsRequestFilterConditions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'definition_key': value.definitionKey,
        'matching_groups': ((value.matchingGroups as Array<any>).map(ReferralReportFilterConditionMatcherToJSON)),
    };
}

