import { ParsedUrlQuery } from 'querystring';

import { useRouter } from 'next/router';

// パスをキー、"戻る"ボタンの戻り先を生成する関数を値に取るマップ
const backLinksByPathname = new Map<string, (query: ParsedUrlQuery) => string>([
  // タスク
  // ParsedUrlQuery から取得した id は型定義上 string | string[] | undefined だが、
  // pathnameから id は string であることが確かなので `as string` で強制的に string として扱っている。
  ['/crms/[id]/tasks', ({ id }) => `/crms/${id as string}`],
  // 紹介
  ['/crms/[id]/referrals', ({ id }) => `/crms/${id as string}`],
  // コミュニケーション
  ['/crms/[id]/communications', ({ id }) => `/crms/${id as string}`],
  // HTMLメールレポート
  ['/emails/[id]/reports', () => '/emails'],
  // HTMLメール配信
  ['/emails/new', () => '/emails'],
  // HTMLメール配信
  ['/emails/[id]/edit', () => '/emails?status=unsent'],
  // ラベル管理
  [
    '/labels/medical_institutions/import',
    () => '/labels?tab=MedicalInstitutionLabels',
  ],
  // ラベル管理
  ['/labels/communications/import', () => '/labels?tab=CommunicationLabels'],
]);

export const useBackLinkHref = (): string | undefined => {
  const router = useRouter();
  const backLinkInQuery = router.query.backLink as string | undefined;

  return (
    backLinkInQuery || backLinksByPathname.get(router.pathname)?.(router.query)
  );
};
