/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PutPhaseMastersRequestPhaseMasters,
    PutPhaseMastersRequestPhaseMastersFromJSON,
    PutPhaseMastersRequestPhaseMastersFromJSONTyped,
    PutPhaseMastersRequestPhaseMastersToJSON,
} from './PutPhaseMastersRequestPhaseMasters';

/**
 * 
 * @export
 * @interface PutPhaseMastersRequest
 */
export interface PutPhaseMastersRequest {
    /**
     * 
     * @type {Array<PutPhaseMastersRequestPhaseMasters>}
     * @memberof PutPhaseMastersRequest
     */
    phaseMasters: Array<PutPhaseMastersRequestPhaseMasters>;
}

export function PutPhaseMastersRequestFromJSON(json: any): PutPhaseMastersRequest {
    return PutPhaseMastersRequestFromJSONTyped(json, false);
}

export function PutPhaseMastersRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutPhaseMastersRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'phaseMasters': ((json['phase_masters'] as Array<any>).map(PutPhaseMastersRequestPhaseMastersFromJSON)),
    };
}

export function PutPhaseMastersRequestToJSON(value?: PutPhaseMastersRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'phase_masters': ((value.phaseMasters as Array<any>).map(PutPhaseMastersRequestPhaseMastersToJSON)),
    };
}

