/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 紹介レポート集計結果行
 * @export
 * @interface ReferralReportAggregationResultRow
 */
export interface ReferralReportAggregationResultRow {
    /**
     * 
     * @type {Array<string>}
     * @memberof ReferralReportAggregationResultRow
     */
    groupingKeys: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ReferralReportAggregationResultRow
     */
    referralCount: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReferralReportAggregationResultRow
     */
    hospitalAdmissionCount: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReferralReportAggregationResultRow
     */
    hospitalAdmissionRate: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReferralReportAggregationResultRow
     */
    operationCount: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReferralReportAggregationResultRow
     */
    operationRate: number | null;
}

export function ReferralReportAggregationResultRowFromJSON(json: any): ReferralReportAggregationResultRow {
    return ReferralReportAggregationResultRowFromJSONTyped(json, false);
}

export function ReferralReportAggregationResultRowFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReferralReportAggregationResultRow {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'groupingKeys': json['grouping_keys'],
        'referralCount': json['referral_count'],
        'hospitalAdmissionCount': json['hospital_admission_count'],
        'hospitalAdmissionRate': json['hospital_admission_rate'],
        'operationCount': json['operation_count'],
        'operationRate': json['operation_rate'],
    };
}

export function ReferralReportAggregationResultRowToJSON(value?: ReferralReportAggregationResultRow | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'grouping_keys': value.groupingKeys,
        'referral_count': value.referralCount,
        'hospital_admission_count': value.hospitalAdmissionCount,
        'hospital_admission_rate': value.hospitalAdmissionRate,
        'operation_count': value.operationCount,
        'operation_rate': value.operationRate,
    };
}

