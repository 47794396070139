/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NullableStaff,
    NullableStaffFromJSON,
    NullableStaffFromJSONTyped,
    NullableStaffToJSON,
} from './NullableStaff';

/**
 * 
 * @export
 * @interface NullableMedicalInstitutionPhase
 */
export interface NullableMedicalInstitutionPhase {
    /**
     * 医療機関ID
     * @type {number}
     * @memberof NullableMedicalInstitutionPhase
     */
    medicalInstitutionId: number;
    /**
     * フェーズマスタID
     * @type {string}
     * @memberof NullableMedicalInstitutionPhase
     */
    phaseMasterId: string;
    /**
     * 
     * @type {NullableStaff}
     * @memberof NullableMedicalInstitutionPhase
     */
    changedUser: NullableStaff | null;
    /**
     * 更新日時
     * @type {string}
     * @memberof NullableMedicalInstitutionPhase
     */
    changedAt: string;
}

export function NullableMedicalInstitutionPhaseFromJSON(json: any): NullableMedicalInstitutionPhase {
    return NullableMedicalInstitutionPhaseFromJSONTyped(json, false);
}

export function NullableMedicalInstitutionPhaseFromJSONTyped(json: any, ignoreDiscriminator: boolean): NullableMedicalInstitutionPhase {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'medicalInstitutionId': json['medical_institution_id'],
        'phaseMasterId': json['phase_master_id'],
        'changedUser': NullableStaffFromJSON(json['changed_user']),
        'changedAt': json['changed_at'],
    };
}

export function NullableMedicalInstitutionPhaseToJSON(value?: NullableMedicalInstitutionPhase | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'medical_institution_id': value.medicalInstitutionId,
        'phase_master_id': value.phaseMasterId,
        'changed_user': NullableStaffToJSON(value.changedUser),
        'changed_at': value.changedAt,
    };
}

