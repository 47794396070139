import { CurrentUserRoleEnum } from '@/src/api/generated';
import { Popover } from '@/src/components/foundations/Utils/Popover';
import { PopoverMenu } from '@/src/components/foundations/Utils/PopoverMenu';
import { useAccount } from '@/src/hooks/useAccount';
import { isFeatureFlagEnabled } from '@/src/utils/featureFlag';

import styles from './styles.module.scss';
import { PresenterProps } from './types';

import AccountCircleOutlined from '@mui/icons-material/AccountCircleOutlined';
import Dehaze from '@mui/icons-material/Dehaze';
import ExitToApp from '@mui/icons-material/ExitToApp';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import compact from 'lodash.compact';

export const NavigationPresenter: React.FC<PresenterProps> = (props) => {
  const { account } = useAccount();
  const menus: React.ComponentProps<typeof PopoverMenu>['menus'] =
    isFeatureFlagEnabled('FEATURE_FLAG_PHASE_MANAGEMENT')
      ? compact([
          // テナント設定はオーナーの場合のみ利用可能
          account.role === CurrentUserRoleEnum.Owner
            ? {
                label: 'テナント設定',
                type: 'basic',
                onClick: props.onClickGoToTenantSettings,
              }
            : undefined,
          {
            label: 'ユーザー設定',
            type: 'basic',
            onClick: props.onClickGoToProfileNotification,
          },
          {
            label: 'ログアウト',
            type: 'basic',
            icon: <ExitToApp />,
            onClick: props.onClickLogoutButton,
          },
        ])
      : [
          {
            label: '設定',
            type: 'basic',
            icon: <SettingsOutlinedIcon />,
            onClick: props.onClickGoToProfileNotification,
          },
          {
            label: 'ログアウト',
            type: 'basic',
            icon: <ExitToApp />,
            onClick: props.onClickLogoutButton,
          },
        ];

  return (
    <nav className={styles.base}>
      <div className={styles.menuItem}>
        <button className={styles.menuButton} onClick={props.onClickMenuButton}>
          <Dehaze className={styles.menuIcon} />
        </button>
      </div>
      <p className={styles.title}>{props.title}</p>
      <Popover
        trigger={
          <button className={styles.account} onClick={props.showPopover}>
            <AccountCircleOutlined className={styles.accountIcon} />
            <span className={styles.accountName}>{props.name}</span>
          </button>
        }
        placement="right"
        isShown={props.isShownPopover}
        hide={props.hidePopover}
      >
        <PopoverMenu menus={menus} />
      </Popover>
    </nav>
  );
};
