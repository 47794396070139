import { MainLayout } from '@/src/layouts/MainLayout';
import { CustomLayout, LayoutOption } from '@/src/types/layout';

export const Layout: React.FC<{
  type?: CustomLayout;
  options: { pc?: LayoutOption; sp?: LayoutOption };
  children: React.ReactNode;
}> = ({ children, type, options }) => {
  switch (type) {
    case 'none':
      return <>{children}</>;
    default:
      return (
        <MainLayout
          noPadding={{
            pc:
              options.pc === 'no-padding' ||
              options.pc === 'no-padding-no-sticky-header',
            sp:
              options.sp === 'no-padding' ||
              options.sp === 'no-padding-no-sticky-header',
          }}
          noStickyHeader={{
            pc: options.pc === 'no-padding-no-sticky-header',
            sp: options.sp === 'no-padding-no-sticky-header',
          }}
        >
          {children}
        </MainLayout>
      );
  }
};
