/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PhaseMaster,
    PhaseMasterFromJSON,
    PhaseMasterFromJSONTyped,
    PhaseMasterToJSON,
} from './PhaseMaster';

/**
 * 
 * @export
 * @interface PutPhaseMastersResponse
 */
export interface PutPhaseMastersResponse {
    /**
     * 
     * @type {Array<PhaseMaster>}
     * @memberof PutPhaseMastersResponse
     */
    phaseMasters: Array<PhaseMaster>;
}

export function PutPhaseMastersResponseFromJSON(json: any): PutPhaseMastersResponse {
    return PutPhaseMastersResponseFromJSONTyped(json, false);
}

export function PutPhaseMastersResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutPhaseMastersResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'phaseMasters': ((json['phase_masters'] as Array<any>).map(PhaseMasterFromJSON)),
    };
}

export function PutPhaseMastersResponseToJSON(value?: PutPhaseMastersResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'phase_masters': ((value.phaseMasters as Array<any>).map(PhaseMasterToJSON)),
    };
}

