/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OwnDepartmentReportFilterCondition,
    OwnDepartmentReportFilterConditionFromJSON,
    OwnDepartmentReportFilterConditionFromJSONTyped,
    OwnDepartmentReportFilterConditionToJSON,
} from './OwnDepartmentReportFilterCondition';
import {
    OwnDepartmentReportItem,
    OwnDepartmentReportItemFromJSON,
    OwnDepartmentReportItemFromJSONTyped,
    OwnDepartmentReportItemToJSON,
} from './OwnDepartmentReportItem';
import {
    OwnDepartmentReportSortCondition,
    OwnDepartmentReportSortConditionFromJSON,
    OwnDepartmentReportSortConditionFromJSONTyped,
    OwnDepartmentReportSortConditionToJSON,
} from './OwnDepartmentReportSortCondition';

/**
 * 
 * @export
 * @interface PostOwnDepartmentReportResultsRequest
 */
export interface PostOwnDepartmentReportResultsRequest {
    /**
     * 表示項目一覧
     * @type {Array<OwnDepartmentReportItem>}
     * @memberof PostOwnDepartmentReportResultsRequest
     */
    displayItems: Array<OwnDepartmentReportItem>;
    /**
     * 絞り込み条件一覧
     * @type {Array<OwnDepartmentReportFilterCondition>}
     * @memberof PostOwnDepartmentReportResultsRequest
     */
    filterConditions: Array<OwnDepartmentReportFilterCondition>;
    /**
     * 並び替え条件一覧
     * @type {Array<OwnDepartmentReportSortCondition>}
     * @memberof PostOwnDepartmentReportResultsRequest
     */
    sortConditions: Array<OwnDepartmentReportSortCondition>;
    /**
     * レポート全体に適用される施設タイプの集計条件
     * @type {Array<number>}
     * @memberof PostOwnDepartmentReportResultsRequest
     */
    aggregationConditionFacilityTypeIds?: Array<number> | null;
    /**
     * 表示するページ番号
     * @type {number}
     * @memberof PostOwnDepartmentReportResultsRequest
     */
    page: number;
    /**
     * ページあたりの件数
     * @type {number}
     * @memberof PostOwnDepartmentReportResultsRequest
     */
    perPage: number;
}

export function PostOwnDepartmentReportResultsRequestFromJSON(json: any): PostOwnDepartmentReportResultsRequest {
    return PostOwnDepartmentReportResultsRequestFromJSONTyped(json, false);
}

export function PostOwnDepartmentReportResultsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostOwnDepartmentReportResultsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'displayItems': ((json['display_items'] as Array<any>).map(OwnDepartmentReportItemFromJSON)),
        'filterConditions': ((json['filter_conditions'] as Array<any>).map(OwnDepartmentReportFilterConditionFromJSON)),
        'sortConditions': ((json['sort_conditions'] as Array<any>).map(OwnDepartmentReportSortConditionFromJSON)),
        'aggregationConditionFacilityTypeIds': !exists(json, 'aggregation_condition_facility_type_ids') ? undefined : json['aggregation_condition_facility_type_ids'],
        'page': json['page'],
        'perPage': json['per_page'],
    };
}

export function PostOwnDepartmentReportResultsRequestToJSON(value?: PostOwnDepartmentReportResultsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'display_items': ((value.displayItems as Array<any>).map(OwnDepartmentReportItemToJSON)),
        'filter_conditions': ((value.filterConditions as Array<any>).map(OwnDepartmentReportFilterConditionToJSON)),
        'sort_conditions': ((value.sortConditions as Array<any>).map(OwnDepartmentReportSortConditionToJSON)),
        'aggregation_condition_facility_type_ids': value.aggregationConditionFacilityTypeIds,
        'page': value.page,
        'per_page': value.perPage,
    };
}

