/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum AreaReportItemDefinitionEnabledAggregationsEnum {
    Month = 'month',
    Dpc6Digits = 'dpc_6_digits',
    OwnDepartmentIds = 'own_department_ids',
    FiscalYear = 'fiscal_year',
    CommunicationType = 'communication_type',
    TenantMedicalInstitutionIds = 'tenant_medical_institution_ids',
    IsDoctorAttendedVisit = 'is_doctor_attended_visit',
    CommunicationLabels = 'communication_labels',
    RevenueTypes = 'revenue_types'
}

export function AreaReportItemDefinitionEnabledAggregationsEnumFromJSON(json: any): AreaReportItemDefinitionEnabledAggregationsEnum {
    return AreaReportItemDefinitionEnabledAggregationsEnumFromJSONTyped(json, false);
}

export function AreaReportItemDefinitionEnabledAggregationsEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): AreaReportItemDefinitionEnabledAggregationsEnum {
    return json as AreaReportItemDefinitionEnabledAggregationsEnum;
}

export function AreaReportItemDefinitionEnabledAggregationsEnumToJSON(value?: AreaReportItemDefinitionEnabledAggregationsEnum | null): any {
    return value as any;
}

