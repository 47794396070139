import { CurrentUserRoleEnum } from '@/src/api/generated';
import { Img } from '@/src/components/foundations/DataDisplays/Img';
import { Link } from '@/src/components/foundations/Utils/Link';
import { Popover } from '@/src/components/foundations/Utils/Popover';
import { PopoverMenu } from '@/src/components/foundations/Utils/PopoverMenu';
import { useAccount } from '@/src/hooks/useAccount';
import { isFeatureFlagEnabled } from '@/src/utils/featureFlag';

import styles from './spStyles.module.scss';
import { PresenterProps } from './types';

import AccountCircleOutlined from '@mui/icons-material/AccountCircleOutlined';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import Dehaze from '@mui/icons-material/Dehaze';
import ExitToApp from '@mui/icons-material/ExitToApp';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import compact from 'lodash.compact';

export const SpNavigationPresenter: React.FC<PresenterProps> = (props) => {
  const { account } = useAccount();
  const menus: React.ComponentProps<typeof PopoverMenu>['menus'] =
    isFeatureFlagEnabled('FEATURE_FLAG_PHASE_MANAGEMENT')
      ? compact([
          {
            label: props.name,
            type: 'basic',
            icon: <AccountCircleOutlined />,
            onClick: () => null,
            className: styles.accountName,
          },
          // テナント設定はオーナーの場合のみ利用可能
          account.role === CurrentUserRoleEnum.Owner
            ? {
                label: 'テナント設定',
                type: 'basic',
                onClick: props.onClickGoToTenantSettings,
              }
            : undefined,
          {
            label: 'ユーザー設定',
            type: 'basic',
            onClick: props.onClickGoToProfileNotification,
          },
          {
            label: 'ログアウト',
            type: 'basic',
            icon: <ExitToApp />,
            onClick: props.onClickLogoutButton,
          },
        ])
      : [
          {
            label: props.name,
            type: 'basic',
            icon: <AccountCircleOutlined />,
            onClick: () => null,
            className: styles.accountName,
          },
          {
            label: '設定',
            type: 'basic',
            icon: <SettingsOutlinedIcon />,
            onClick: props.onClickGoToProfileNotification,
            isTopDivider: true,
          },
          {
            label: 'ログアウト',
            type: 'basic',
            icon: <ExitToApp />,
            onClick: props.onClickLogoutButton,
          },
        ];

  return (
    <>
      <nav className={styles.navigation}>
        <button className={styles.menuButton} onClick={props.onClickMenuButton}>
          <Dehaze className={styles.menuIcon} />
        </button>
        <Link href="/medical_institutions_search">
          <Img className={styles.logo} alt="logo" src="/logo.png" />
        </Link>
        <Popover
          trigger={
            <button className={styles.account} onClick={props.showPopover}>
              <AccountCircleOutlined className={styles.accountIcon} />
            </button>
          }
          placement="right"
          isShown={props.isShownPopover}
          hide={props.hidePopover}
        >
          <PopoverMenu menus={menus} />
        </Popover>
      </nav>
      <div className={styles.titleWrapper}>
        {props.backLinkHref && (
          <Link href={props.backLinkHref} className={styles.backLink}>
            <ChevronLeft />
          </Link>
        )}
        <div className={styles.title}>{props.title}</div>
      </div>
    </>
  );
};
