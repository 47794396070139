/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ReferralReportAggregationResultRow,
    ReferralReportAggregationResultRowFromJSON,
    ReferralReportAggregationResultRowFromJSONTyped,
    ReferralReportAggregationResultRowToJSON,
} from './ReferralReportAggregationResultRow';

/**
 * 
 * @export
 * @interface PostReferralReportAggregationResultsResponse
 */
export interface PostReferralReportAggregationResultsResponse {
    /**
     * 
     * @type {Array<ReferralReportAggregationResultRow>}
     * @memberof PostReferralReportAggregationResultsResponse
     */
    results: Array<ReferralReportAggregationResultRow>;
}

export function PostReferralReportAggregationResultsResponseFromJSON(json: any): PostReferralReportAggregationResultsResponse {
    return PostReferralReportAggregationResultsResponseFromJSONTyped(json, false);
}

export function PostReferralReportAggregationResultsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostReferralReportAggregationResultsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'results': ((json['results'] as Array<any>).map(ReferralReportAggregationResultRowFromJSON)),
    };
}

export function PostReferralReportAggregationResultsResponseToJSON(value?: PostReferralReportAggregationResultsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'results': ((value.results as Array<any>).map(ReferralReportAggregationResultRowToJSON)),
    };
}

