/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PutMedicalInstitutionPhaseRequest
 */
export interface PutMedicalInstitutionPhaseRequest {
    /**
     * フェーズマスタのID、nullの場合はフェーズを未設定状態にする
     * @type {string}
     * @memberof PutMedicalInstitutionPhaseRequest
     */
    phaseMasterId: string | null;
}

export function PutMedicalInstitutionPhaseRequestFromJSON(json: any): PutMedicalInstitutionPhaseRequest {
    return PutMedicalInstitutionPhaseRequestFromJSONTyped(json, false);
}

export function PutMedicalInstitutionPhaseRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutMedicalInstitutionPhaseRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'phaseMasterId': json['phase_master_id'],
    };
}

export function PutMedicalInstitutionPhaseRequestToJSON(value?: PutMedicalInstitutionPhaseRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'phase_master_id': value.phaseMasterId,
    };
}

