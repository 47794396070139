/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReferralReportFilterConditionMatcher
 */
export interface ReferralReportFilterConditionMatcher {
    /**
     * 指定した文字列から始まっている場合にマッチする条件
     * @type {string}
     * @memberof ReferralReportFilterConditionMatcher
     */
    startWith?: string;
    /**
     * 指定した文字列が含まれる場合にマッチする条件
     * @type {string}
     * @memberof ReferralReportFilterConditionMatcher
     */
    contain?: string;
    /**
     * 指定した文字列が含まれない場合にマッチする条件
     * @type {string}
     * @memberof ReferralReportFilterConditionMatcher
     */
    notContain?: string;
    /**
     * trueにすると値が存在するものにのみマッチする。falseにすると値が存在しない（null）対象も含めてマッチする。
     * @type {boolean}
     * @memberof ReferralReportFilterConditionMatcher
     */
    _exists?: boolean;
    /**
     * 指定した数値以上の場合にマッチする条件
     * @type {number}
     * @memberof ReferralReportFilterConditionMatcher
     */
    moreEqual?: number | null;
    /**
     * 指定した数値以下の場合にマッチする条件
     * @type {number}
     * @memberof ReferralReportFilterConditionMatcher
     */
    lessEqual?: number | null;
    /**
     * 数値が指定したいずれかと合致する場合にマッチする条件
     * @type {Array<number>}
     * @memberof ReferralReportFilterConditionMatcher
     */
    _in?: Array<number>;
    /**
     * 数値が指定したいずれも含まない場合にマッチする条件
     * @type {Array<number>}
     * @memberof ReferralReportFilterConditionMatcher
     */
    notIn?: Array<number>;
    /**
     * (in 同様) 文字列が指定したいずれかと合致する場合にマッチする条件
     * @type {Array<string>}
     * @memberof ReferralReportFilterConditionMatcher
     */
    stringIn?: Array<string>;
    /**
     * 指定した時刻、もしくはそれ以前の場合にマッチする条件
     * @type {string}
     * @memberof ReferralReportFilterConditionMatcher
     */
    startTime?: string | null;
    /**
     * 指定した時刻、もしくはそれ以降の場合にマッチする条件
     * @type {string}
     * @memberof ReferralReportFilterConditionMatcher
     */
    endTime?: string | null;
    /**
     * 指定した日付、もしくはそれ以降の場合にマッチする条件
     * @type {string}
     * @memberof ReferralReportFilterConditionMatcher
     */
    startDate?: string | null;
    /**
     * 指定した日付、もしくはそれ以前の場合にマッチする条件
     * @type {string}
     * @memberof ReferralReportFilterConditionMatcher
     */
    endDate?: string | null;
    /**
     * 指定した月、もしくはそれ以降の場合にマッチする条件
     * @type {string}
     * @memberof ReferralReportFilterConditionMatcher
     */
    startMonth?: string | null;
    /**
     * 指定した月、もしくはそれ以前の場合にマッチする条件
     * @type {string}
     * @memberof ReferralReportFilterConditionMatcher
     */
    endMonth?: string | null;
    /**
     * 指定したもののうちどれか一つでも含んでいる場合にマッチする条件。数値配列に指定可能。
     * @type {Array<number>}
     * @memberof ReferralReportFilterConditionMatcher
     */
    haveAny?: Array<number>;
    /**
     * 指定したものをすべてを含んでいる場合にマッチする条件。数値配列に指定可能。
     * @type {Array<number>}
     * @memberof ReferralReportFilterConditionMatcher
     */
    haveAll?: Array<number>;
    /**
     * 指定したものをすべてを含んでいない場合にマッチする条件。数値配列に指定可能。
     * @type {Array<number>}
     * @memberof ReferralReportFilterConditionMatcher
     */
    notHaveAll?: Array<number>;
}

export function ReferralReportFilterConditionMatcherFromJSON(json: any): ReferralReportFilterConditionMatcher {
    return ReferralReportFilterConditionMatcherFromJSONTyped(json, false);
}

export function ReferralReportFilterConditionMatcherFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReferralReportFilterConditionMatcher {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'startWith': !exists(json, 'start_with') ? undefined : json['start_with'],
        'contain': !exists(json, 'contain') ? undefined : json['contain'],
        'notContain': !exists(json, 'not_contain') ? undefined : json['not_contain'],
        '_exists': !exists(json, 'exists') ? undefined : json['exists'],
        'moreEqual': !exists(json, 'more_equal') ? undefined : json['more_equal'],
        'lessEqual': !exists(json, 'less_equal') ? undefined : json['less_equal'],
        '_in': !exists(json, 'in') ? undefined : json['in'],
        'notIn': !exists(json, 'not_in') ? undefined : json['not_in'],
        'stringIn': !exists(json, 'string_in') ? undefined : json['string_in'],
        'startTime': !exists(json, 'start_time') ? undefined : json['start_time'],
        'endTime': !exists(json, 'end_time') ? undefined : json['end_time'],
        'startDate': !exists(json, 'start_date') ? undefined : json['start_date'],
        'endDate': !exists(json, 'end_date') ? undefined : json['end_date'],
        'startMonth': !exists(json, 'start_month') ? undefined : json['start_month'],
        'endMonth': !exists(json, 'end_month') ? undefined : json['end_month'],
        'haveAny': !exists(json, 'have_any') ? undefined : json['have_any'],
        'haveAll': !exists(json, 'have_all') ? undefined : json['have_all'],
        'notHaveAll': !exists(json, 'not_have_all') ? undefined : json['not_have_all'],
    };
}

export function ReferralReportFilterConditionMatcherToJSON(value?: ReferralReportFilterConditionMatcher | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'start_with': value.startWith,
        'contain': value.contain,
        'not_contain': value.notContain,
        'exists': value._exists,
        'more_equal': value.moreEqual,
        'less_equal': value.lessEqual,
        'in': value._in,
        'not_in': value.notIn,
        'string_in': value.stringIn,
        'start_time': value.startTime,
        'end_time': value.endTime,
        'start_date': value.startDate,
        'end_date': value.endDate,
        'start_month': value.startMonth,
        'end_month': value.endMonth,
        'have_any': value.haveAny,
        'have_all': value.haveAll,
        'not_have_all': value.notHaveAll,
    };
}

