/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PostReferralReportAggregationResultsRequestFilterConditions,
    PostReferralReportAggregationResultsRequestFilterConditionsFromJSON,
    PostReferralReportAggregationResultsRequestFilterConditionsFromJSONTyped,
    PostReferralReportAggregationResultsRequestFilterConditionsToJSON,
} from './PostReferralReportAggregationResultsRequestFilterConditions';
import {
    PostReferralReportAggregationResultsRequestGroupingConditions,
    PostReferralReportAggregationResultsRequestGroupingConditionsFromJSON,
    PostReferralReportAggregationResultsRequestGroupingConditionsFromJSONTyped,
    PostReferralReportAggregationResultsRequestGroupingConditionsToJSON,
} from './PostReferralReportAggregationResultsRequestGroupingConditions';

/**
 * 
 * @export
 * @interface PostReferralReportAggregationResultsRequest
 */
export interface PostReferralReportAggregationResultsRequest {
    /**
     * 絞り込み条件リスト
     * @type {Array<PostReferralReportAggregationResultsRequestFilterConditions>}
     * @memberof PostReferralReportAggregationResultsRequest
     */
    filterConditions: Array<PostReferralReportAggregationResultsRequestFilterConditions>;
    /**
     * グルーピング条件リスト
     * @type {Array<PostReferralReportAggregationResultsRequestGroupingConditions>}
     * @memberof PostReferralReportAggregationResultsRequest
     */
    groupingConditions: Array<PostReferralReportAggregationResultsRequestGroupingConditions>;
}

export function PostReferralReportAggregationResultsRequestFromJSON(json: any): PostReferralReportAggregationResultsRequest {
    return PostReferralReportAggregationResultsRequestFromJSONTyped(json, false);
}

export function PostReferralReportAggregationResultsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostReferralReportAggregationResultsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'filterConditions': ((json['filter_conditions'] as Array<any>).map(PostReferralReportAggregationResultsRequestFilterConditionsFromJSON)),
        'groupingConditions': ((json['grouping_conditions'] as Array<any>).map(PostReferralReportAggregationResultsRequestGroupingConditionsFromJSON)),
    };
}

export function PostReferralReportAggregationResultsRequestToJSON(value?: PostReferralReportAggregationResultsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'filter_conditions': ((value.filterConditions as Array<any>).map(PostReferralReportAggregationResultsRequestFilterConditionsToJSON)),
        'grouping_conditions': ((value.groupingConditions as Array<any>).map(PostReferralReportAggregationResultsRequestGroupingConditionsToJSON)),
    };
}

