/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Forbidden,
    ForbiddenFromJSON,
    ForbiddenToJSON,
    PutPhaseMastersRequest,
    PutPhaseMastersRequestFromJSON,
    PutPhaseMastersRequestToJSON,
    PutPhaseMastersResponse,
    PutPhaseMastersResponseFromJSON,
    PutPhaseMastersResponseToJSON,
    UnprocessableEntity,
    UnprocessableEntityFromJSON,
    UnprocessableEntityToJSON,
} from '../models';

export interface PutPhaseMastersOperationRequest {
    putPhaseMastersRequest: PutPhaseMastersRequest;
}

/**
 * PhaseMastersApi - interface
 * 
 * @export
 * @interface PhaseMastersApiInterface
 */
export interface PhaseMastersApiInterface {
    /**
     * 
     * @summary フェーズマスタの状態を更新する
     * @param {PutPhaseMastersRequest} putPhaseMastersRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhaseMastersApiInterface
     */
    putPhaseMastersRaw(requestParameters: PutPhaseMastersOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PutPhaseMastersResponse>>;

    /**
     * フェーズマスタの状態を更新する
     */
    putPhaseMasters(requestParameters: PutPhaseMastersOperationRequest, initOverrides?: RequestInit): Promise<PutPhaseMastersResponse>;

}

/**
 * 
 */
export class PhaseMastersApi extends runtime.BaseAPI implements PhaseMastersApiInterface {

    /**
     * フェーズマスタの状態を更新する
     */
    async putPhaseMastersRaw(requestParameters: PutPhaseMastersOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PutPhaseMastersResponse>> {
        if (requestParameters.putPhaseMastersRequest === null || requestParameters.putPhaseMastersRequest === undefined) {
            throw new runtime.RequiredError('putPhaseMastersRequest','Required parameter requestParameters.putPhaseMastersRequest was null or undefined when calling putPhaseMasters.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/phase_masters`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PutPhaseMastersRequestToJSON(requestParameters.putPhaseMastersRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PutPhaseMastersResponseFromJSON(jsonValue));
    }

    /**
     * フェーズマスタの状態を更新する
     */
    async putPhaseMasters(requestParameters: PutPhaseMastersOperationRequest, initOverrides?: RequestInit): Promise<PutPhaseMastersResponse> {
        const response = await this.putPhaseMastersRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
