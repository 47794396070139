/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostReferralReportAggregationResultsRequestGroupingConditions
 */
export interface PostReferralReportAggregationResultsRequestGroupingConditions {
    /**
     * 
     * @type {string}
     * @memberof PostReferralReportAggregationResultsRequestGroupingConditions
     */
    definitionKey: string;
    /**
     * 
     * @type {string}
     * @memberof PostReferralReportAggregationResultsRequestGroupingConditions
     */
    dateGrouping?: PostReferralReportAggregationResultsRequestGroupingConditionsDateGroupingEnum;
}

/**
* @export
* @enum {string}
*/
export enum PostReferralReportAggregationResultsRequestGroupingConditionsDateGroupingEnum {
    Month = 'month',
    HalfYear = 'half_year',
    FiscalYear = 'fiscal_year'
}

export function PostReferralReportAggregationResultsRequestGroupingConditionsFromJSON(json: any): PostReferralReportAggregationResultsRequestGroupingConditions {
    return PostReferralReportAggregationResultsRequestGroupingConditionsFromJSONTyped(json, false);
}

export function PostReferralReportAggregationResultsRequestGroupingConditionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostReferralReportAggregationResultsRequestGroupingConditions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'definitionKey': json['definition_key'],
        'dateGrouping': !exists(json, 'date_grouping') ? undefined : json['date_grouping'],
    };
}

export function PostReferralReportAggregationResultsRequestGroupingConditionsToJSON(value?: PostReferralReportAggregationResultsRequestGroupingConditions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'definition_key': value.definitionKey,
        'date_grouping': value.dateGrouping,
    };
}

